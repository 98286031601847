import { createUseStyles } from 'react-jss'

import {
  borderSeparator
} from '~styles/mixins.styles'

export default createUseStyles({
  links: {
    ...borderSeparator('bottom')
  }
})