import React, { useEffect, useState }  from 'react';
import ReactDOMServer from 'react-dom/server';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import useStyles from './faq-questions.styles';

const FAQ_CONTENT = [{
  'question': 'Tell me again what is happening?',
  'answer': 'On January 7th, 2020, we will start charging for text messages.  Email notifications will continue to be free.'
}, {
  'question': 'What is still free?',
  'answer': 'Creating an account and sending/receiving email messages.  We have to charge for EITHER sending or receiving text messages, but not both.  So, we have two plans – one where the sender pays and one where the recipient pays.  Only one plan is necessary per account.'
}, {
  'question': 'Why are you charging now when everything was free before?',
  'answer': 'When we started out, we were just helping a couple of teams and we paid all the text messages fees out of our own pockets. As RainedOut has grown, so have the charges from the carriers.  We want to provide better service, get rid of the ads, build a new website, add some nice new features.  To do this, we have to ask you to pay for the first time in the history of our company.'
}, {
  'question': 'Will you sell my data?',
  'answer': 'No.  Many companies do this, and this could be a revenue source for RainedOut, however we do not believe that it is the right thing to do.'
}, {
  'question': 'Are you improving RainedOut?',
  'answer': 'Yes, we are also going to eliminate ads, provide longer messages, new website, and many new features that you have requested.'
}, {
  'question': 'How are you going to charge?',
  'answer': 'Each organization will choose the plan they think is best.  Only one plan can be used per account.  There will be two options - sender pay or recipient pay.'
}, {
  'question': 'Who typically chooses the Sender Pays plan?',
  'answer': 'This plan is for organizations looking to purchase a bundle of messages and use them as needed.  Rec Councils, churches, farmers markets and club teams use this plan for quick updates, field changes and game cancellations.  Libraries and schools use this plan for snow day announcements.'
}, {
  'question': 'Who typically chooses the Recipient’s Choice plan?',
  'answer': 'In this plan, the organization opts to let the Recipient choose unlimited messaging via text or email.  This plan is chosen by organizations that have many recipients that get updates frequently, such news or traffic organizations.  If the Recipient chooses text, there is a small yearly fee.  Email is free.'
}, {
  'question': 'Why would I pick the Sender Pays plan?',
  'answer': 'If you send messages every so often or on an as needed basis.  For example, if you only need to send messages when there is a game cancellation or a snow day.  Many organizations choose this plan and add $1 or $2 dollars to a registration fee.'
}, {
  'question': 'Why would I pick the Recipient’s Choice plan?',
  'answer': 'You send a very high volume of messages and need an unlimited plan.  You may also have many recipients that use the service at different times such as with a large park or arena.  Lastly, you may just decide to let your  recipients choose whether they want  email or texting.'
}, {
  'question': 'How does the Sender Pays plan work?',
  'answer': 'Go to the new website, click Plans and select Sender plan by clicking on the box.  Scroll down and log in using your RainedOut username and password.  When you log in, we will update the calculator with your actual total SMS recipient number. Use the calculator to determine your text messaging needs. Check out and pre-pay for a block of text messages. Messages do not expire. Emails are free. Start sending messages just like you always have!'
}, {
  'question': 'How does the Recipient’s Choice plan work?',
  'answer': 'Go to the new website, click Plans and select Recipient Choice by clicking on the box. Scroll down and log in using your RainedOut username and password. Confirm your choice and you are ready to start sending messages just like you always have.  Once you confirm that you are choosing Recipient Choice, then we will contact everyone receiving texts from you and notify them of the need to make a choice.  If they want to continue receiving text messages, they can pay right from their phones using a credit card, Apple Pay or Android Pay. If they want to receive free emails, they can go RainedOut.com and click Support.  Here they can unsubscribe from text and subscribe for email.'
}, {
  'question': 'Where do I learn more and sign up?',
  'answer': 'The new website has everything you need to pick a plan and sign up.  The new website will be launched January 7th, 2020.'
}, {
  'question': 'What do I do now to get ready?',
  'answer': 'Send a message out to your organization and ask that anyone that no longer wishes to receive text messages from you should reply with STOP.  This will automatically pull them out of your account and leave just the people that need your messages.'
}, {
  'question': 'How do I find out how many people get my messages?',
  'answer': <div className="answer">
    <p>To learn more about your recipients and message volume, log in to your Team Page (where you send messages),</p>
    <p>In the top left corner you will see the following:</p>
    <ul>
      <li>SMS - this is how many people are getting text messages from you right now</li>
    <li>Email - this is how many people are getting emails from you right now</li>
    <li>Messages - this is how many messages you have sent since the account was created</li>
    </ul>
    <p>In the top right corner you will see the following:</p>
    <ul>
    <li>Export All Messages - this will download all your messages in to a spreadsheet</li>
    <li>View More - this window will show you every message you have sent</li>
    </ul>
  </div>
}, {
  'question': 'What plans do you offer?',
  'answer': 'We are offering two plans.  One where the sender pays and one where the recipient pays.  It is up to the organization to choose ONE plan.  If the sender pays, then the recipient does not.  If the recipient pays, then the sender does not.'
}, {
  'question': 'What is the Sender Plan?',
  'answer': 'If your organization wants to pre-pay for text messages on a Pay As You Go basis, then that is 4 cents per text message.  So 1 message to 100 people would cost 1 message X 100 people X 4 cents = $4. Each message in this example would be $4.  If you choose this option, there is no cost to the recipient.'
}, {
  'question': 'What is the Recipient Plan?',
  'answer': 'Your organization can also decide to let the receiver of the message choose what they want.  If they choose text, then we charge each recipient $7.30/year to receive unlimited text messages from unlimited RainedOut accounts.  If they choose email, then that is free.  If your organization chooses this option, then there is no cost to the organization.'
}, {
  'question': 'Who pays the fee?',
  'answer': 'It is up to the organization to choose ONE plan.  If the sender pays, then the recipient does not.  If the recipient pays, then then sender does not.'
}, {
  'question': 'Where do I sign up for a plan?',
  'answer': 'You will sign up on our new website that is being launched in December.'
}, {
  'question': 'Can I choose different plans?',
  'answer': 'It is only possible to choose one payment plan per account.'
}, {
  'question': 'Where is the ad free version?',
  'answer': 'We no long have an ad free version.  We eliminated the ads due to customer feedback.'
}, {
  'question': 'Why should I choose Texting?',
  'answer': 'We think that text messages are easier and faster to get.  They are conversational, short and easy to understand.'
}, {
  'question': 'Why do you charge for texting?',
  'answer': 'We are charged by the carriers to send text messages.  Also, we don’t have any ads and we don’t sell your data, so we have to charge a small fee.'
}, {
  'question': 'How do I stop getting text messages?',
  'answer': 'Just reply with STOP to the next text message you get.'
}, {
  'question': 'How do I stop getting email messages?',
  'answer': 'Send your email address to care@rainedout.com'
}, {
  'question': 'How do I find out my keyword?',
  'answer': 'Log in to the Admin Portal.  Go to Settings and scroll down and you will see your keyword.'
}, {
  'question': 'How do I find out the keyword for my groups?',
  'answer': 'Log in to the Admin Portal.  Go to Groups and you will see the keyword for each group.'
}, {
  'question': 'How do I sign up to send messages from RainedOut?',
  'answer': 'If you would like to issue alerts to a team or organization, you can sign up for a free organization account on the RainedOut website.'
}, {
  'question': 'How do I sign up to receive messages from RainedOut?',
  'answer': 'If you are a player, official, coach, parent or fan and would like to receive alerts from your organization, go to the RainedOut website and start by typing in your organization’s name in the search bar.'
}, {
  'question': 'How can I test my phone to make sure I can receive messages?',
  'answer': 'To test your phone, text TEST to 84483'
}, {
  'question': 'Will I receive messages from RainedOut or advertisements from Sponsors?',
  'answer': 'No.  With RainedOut v2, we no longer offer advertisements.  Additionally, we have never and we will sell your data to marketing firms.'
}, {
  'question': 'What does the service cost?',
  'answer': 'The service is FREE for organizations to set up, and is FREE for players, parents, coaches, officials, fans, and others to receive email messages.  SMS messages carry a fee that EITHER the sender or the receiver will pay.  RainedOut sends standard text messages from short code 84483.  Standard text message charges may apply from your wireless provider, depending on your text message plan. If you have an unlimited text messaging plan, then there would be no additional charge.'
}, {
  'question': 'What is "Msg & data rates may apply"?',
  'answer': 'Message and data rates may apply.  In other words, when you use our text message services, your wireless provider may charge you to send and receive text messages depending on your plan.'
}, {
  'question': 'Do I need to install software and/or hardware in order to make this work?',
  'answer': 'No – there is no App required and No install required.  RainedOut is a mobile service and web-based software system, so you do not need any additional software or hardware. All you need is a standard cell phone and a Web browser with an Internet connection and you can participate!  Additionally, you may opt-in to RainedOut alerts from your mobile phone by texting special keywords that designate an organization or group.'
}, {
  'question': 'How do I stop receiving alerts?',
  'answer': 'There are two ways to stop receiving alerts. Follow the instructions on the Opt-Out page.  The easiest way is to just reply with STOP to the last email you received.'
}, {
  'question': 'Why am I no longer receiving text messages?',
  'answer': `If you have been getting text messages from RainedOut but they've suddenly stopped, this is likely an issue with your wireless provider. Please contact your carrier and inform them that you are no longer able to receive messages from RainedOut (short code 84483). To test your phone, text TEST to 84483.`
}, {
  'question': 'If I belong to more than one organization, do I need to sign up for each separately?',
  'answer': 'Yes. Each RainedOut organization/group is independent. As such, organizations cannot share users or access users in other groups. This protects your privacy and allows the organizations to operate independently.'
}, {
  'question': 'What is short code 84483?',
  'answer': `Short codes are special telephone numbers, significantly shorter than full telephone numbers, that can be used to address SMS and MMS messages from mobile phones. RainedOut's parent If you block this short code, then the RainedOut service will not work.`
}, {
  'question': 'Does RainedOut work outside of the United States?',
  'answer': 'No. At this time RainedOut does not support any carriers outside of the United States.'
}, {
  'question': 'Can I see who has signed up for my RainedOut organization/group account?',
  'answer': `No. RainedOut does not collect names for subscribers. Users can sign up with just their mobile number or e-mail address. For user privacy, RainedOut does not expose subscribers' contact information.`
}, {
  'question': 'Can we change the default "FR: RainedOut" on messages we send to our users?',
  'answer': 'Yes. Log into your Admin account and click on the tab "System". Scroll down until you see the "Message From Name:"  You can change this to reflect your group name, but keep it short or abbreviated as this will take precious characters away from your overall message.  Your Organization Account name can be long, but the Message From Name should be short.'
}, {
  'question': 'Can I send messages from my phone?',
  'answer': `Sending messages has never been easier with RainedOut’s new "Remote Messaging" feature. Any cell phone with text messaging capabilities can now remotely send messages.  Whether you're at the field, in your office, or at home...you now have the luxury of sending messages on the go!  Simply text 84483, in the message body type the word send, then hit the space button, next enter your keyword, then hit the space button, and then enter your message!`
}, {
  'question': 'How do I sign up for RainedOut?',
  'answer': <div className="answer">If you are coordinating a league, team, or organization, you can sign up for a free Organization account by <OutboundLink href="https://www.rainedout.net/create_new_account.php" target="_new">starting here.</OutboundLink></div>
}, {
  'question': `Why can’t I upload my member's contact info?`,
  'answer': <div className="answer">Due to the <OutboundLink href="http://www.mmaglobal.com" target="_new">Mobile Marketing Association</OutboundLink> and <OutboundLink href="http://www.ctia.org" target="_new">CTIA</OutboundLink> guidelines, RainedOut cannot allow people to upload contact lists of any sort.  Our Anti-Spam Policy strictly prohibits uploads.  Members are required to opt-in to receive your alerts. Beware of any service provider that allows you to upload member contact data.</div>
}, {
  'question': 'What is short code 84483?',
  'answer': `Short codes are special telephone numbers, significantly shorter than full telephone numbers, that can be used to address SMS and MMS messages from mobile phones. If you block this short code, then Omnilert's services will not work.`
}, {
  'question': 'Which countries do RainedOut operate in?',
  'answer': 'RainedOut web page alerts, email alerts, and integration with Facebook and Twitter work from anywhere in the world with Internet access.  RainedOut alerts via SMS text message are currently supported in the United States of America only.'
}, {
  'question': 'What if we have a change in leadership of our organization? Will RainedOut give us the password to the RainedOut account?',
  'answer': `No. We can't give a password out to you if you're not already the admin in RainedOut for that account.Please work with the former admin to obtain the username and password for your organization's RainedOut. For obvious security reasons, cannot give out a password to anyone who's email address is not listed as the Super Admin for the account. So, please keep this in mind when setting up your account. Create multiple admins where appropriate. If your leadership changes, it's entirely up to you to retrieve log in information for RainedOut`
}, {
  'question': 'How do I add a subscriber?',
  'answer': 'The best way is to have them text your keyword to 84483.  The is the #1 way and is fast and easy.  There are other ways too – just log in to your admin account and click on Invite Users at the top of the screen.'
}]

// Strip html tags from our rendered DOM string.
const regex = /(<([^>]+)>)/ig;

const FaqQuestions = props => { 
  const classes = useStyles(props);
  const [searchTerm, setSearchTerm] = useState('');
  const [questions, setQuestions] = useState(FAQ_CONTENT);

  useEffect(() => {
    const questions = [...FAQ_CONTENT];
    const filtered = questions.filter((item, idx) => {
      if (searchTerm === '' || 
          (typeof item['question'] === 'string' && item['question'].toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) || 
          (typeof item['answer'] === 'string' && item['answer'].toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
          ) {
        return true
      } else if (
        (typeof item['question'] !== 'string' && ReactDOMServer.renderToString(item['question']).toLowerCase().replace(regex, '').indexOf(searchTerm.toLowerCase()) !== -1) ||
        (typeof item['answer'] !== 'string' && ReactDOMServer.renderToString(item['answer']).toLowerCase().replace(regex, '').indexOf(searchTerm.toLowerCase()) !== -1)
        ) {
          return true
      } else {
        return false
      }
    });
    setQuestions(filtered);
  }, [searchTerm])

  return (
    <div className={classes.questions}>
      <div className={classes.search}>
        <input
          className={classes.searchInput}
          onChange={event => setSearchTerm(event.target.value)}
          placeholder="Search FAQs…"
          value={searchTerm}
        />
      </div>
      {questions.length ? questions.map((item, idx) => {
        return (
          <div className={classes.item} key={idx}>
            <div className={classes.question}>
              <span className={classes.number}>{idx + 1}</span>
              <span className={classes.prefix}>Q:</span>
              <span className={classes.content}>{item.question}</span>
            </div>
            <div className={classes.answer}>
              <span className={classes.prefix}>A:</span>
              <span className={classes.content}>{item.answer}</span>
            </div>
          </div>
        )
      }) : <div className={classes.empty}>No matches</div>}
  </div>
  )
}


export default FaqQuestions