import {createUseStyles} from 'react-jss';

import {
  TRANSITION as TRANSITION_COMMON
} from '@theme/vars';

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

import {
  borderSeparator,
  input
} from '~styles/mixins.styles'

export default createUseStyles({
  /**
   * jss-plugin-global uses `@global` decorator allowing us
   * to style against the php form's search results.  These
   * class names are defined by the old php form.
   */
    search: {
      ...borderSeparator('bottom')
    },
    searchInput: {
      ...input,
    },
    empty: {
      ...borderSeparator('bottom')
    },
    questions: {
      color: COLORS.BLUE_MUTED_01,
      letterSpacing:'-0.03777em'
    },
    item: {
      ...borderSeparator('bottom'),
      position: 'relative',
      [`@media (min-width: ${BREAKPOINTS.FAQ_BIG}px)`]: {
        '&:hover $number': {
          transition: `opacity ${TRANSITION_COMMON.DURATION_SM}ms ease-in-out`,
          opacity: 1
        }
      }
    },
    question: {
      display: 'flex',
      marginBottom: 10,
      '& $content': {
        fontWeight: 600
      }
    },
    answer: {
      display: 'flex',
      '& .answer p': {
        color: 'black',
        fontSize: 18
      },
      '& .answer li': {
        color: COLORS.BLUE_MUTED_01,
        fontSize: 18
      }
    },
    number: {
      alignItems: 'center',
      background: COLORS.LIGHT_03,
      borderRadius: '50%',
      color: COLORS.BLUE_MUTED_02,
      display: 'flex',
      fontSize: 11,
      height: 30,
      justifyContent: 'center',
      left: -40,
      opacity: 0,
      position: 'absolute',
      top: 0,
      width: 30
    },
    prefix: {
      color: COLORS.BLUE_MUTED_02,
      fontWeight: 500,
      marginRight: 10
    },
    content: {
      fontWeight: 400
    },
    links: {
      ...borderSeparator('bottom')
    }
})