import React from "react"
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Layout from "~components/layout/layout.component"
import FaqQuestions from '~components/faq-questions/faq-questions.component'
import SEO from "~components/seo/seo.component"

import useStyles from '~components/faq/faq.styles';

/**
 * Deactivate links in header, footer, and other components on FAQ page.
 * so that they are not discoverable pre-launch.
 */
const INCLUDE_NAVIGATION = process.env.GATSBY_INCLUDE_NAVIGATION === 'true';

const FaqPage = props => {
  const classes = useStyles(props);
  
  return (
  <Layout includeNavigation={INCLUDE_NAVIGATION}>
    <SEO title="Frequently Asked Questions" />
    <h1>Frequently Asked Questions</h1>
    {INCLUDE_NAVIGATION ? <div className={classes.links}>
      <div>Sign up here for e-mail or text — <Link to="/team">Sign Up Here</Link></div>
      <div>Opt out here from e-mail or text — <OutboundLink href="https://www.rainedout.net/opt_out.php">Opt Out Here</OutboundLink></div>
    </div> : null}
    <FaqQuestions />
    {INCLUDE_NAVIGATION ? <Link to="/">Go back to the homepage</Link> : null}
  </Layout>
  )}
  
  export default FaqPage
  